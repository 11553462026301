/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios, {
  AxiosInstance,
  AxiosError,
  AxiosResponse,
  AxiosRequestConfig,
} from 'axios'
import { err, ok } from '@modules/result'
import { HttpError } from './HttpError'
import { HttpResult } from './HttpResult'

type IHttpRequest = {
  url: string
  config?: AxiosRequestConfig
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

type Parser<T, M> = {
  toDomain: (dto: T) => M
}

type Error = {
  error: {
    response: {
      data: {
        error: ''
      }
    }
  }
}

interface IHttpService {
  get<T, M>(request: IHttpRequest, parser?: Parser<T, M>): HttpResult<M>
  post<T, M>(request: IHttpRequest, parser?: Parser<T, M>): HttpResult<M>
}

export class HttpService implements IHttpService {
  private readonly axiosService: AxiosInstance

  constructor(baseUrl: string) {
    this.axiosService = axios.create({
      baseURL: baseUrl,
      headers: { 'Content-Type': 'application/json' },
    })

    // this._initializeRequestInterceptor() // TODO: FIns a way to define if is needed.
    this._initializeResponseInterceptor()
  }

  private _initializeResponseInterceptor = () => {
    this.axiosService.interceptors.response.use(
      this._handleResponse,
      this._handleError
    )
  }

  private _handleResponse = (data: AxiosResponse) => data

  protected _handleError = (error: Error) => Promise.reject(error)

  public async get<T, M>(
    { url, config }: IHttpRequest,
    parser?: Parser<T, M>
  ): HttpResult<M> {
    try {
      const response = await this.axiosService.get(url, config)

      return parser ? ok(parser.toDomain(response.data)) : ok(response.data)
    } catch (error) {
      if (this.isAxiosError(error)) {
        const httpError = error.response
          ? HttpError.fromStatus(error.response.status, error.message)
          : HttpError.fromMessage(error.message)

        return err(httpError)
      }

      throw error
    }
  }

  public async post<T, M>(
    { url, data, config }: IHttpRequest,
    parser?: Parser<T, M>
  ): HttpResult<M> {
    try {
      const response = await this.axiosService.post(url, data, config)
      return parser ? ok(parser.toDomain(response.data)) : ok(response.data)
    } catch (error) {
      if (this.isAxiosError(error)) {
        const httpError = error.response
          ? HttpError.fromStatus(
              error.response.status,
              error.message,
              error.response?.data
            )
          : HttpError.fromMessage(error.message)

        return err(httpError)
      }

      throw error
    }
  }

  private isAxiosError(error: unknown): error is AxiosError {
    return (error as AxiosError).isAxiosError !== undefined
  }
}
