import { IEmailRepository } from '@modules/email/domain/email.repository'
import { Email } from '@modules/email/domain/email.types'
import { sendDatadogError, sendDatadogEvent } from '@ui/plugins/datadog'

type Parameters = Email

type Services = {
  emailService: IEmailRepository
}

type Callbacks = {
  // fix any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess: () => void
  onError: () => void
}

function handleGetEmailCheck({
  emailService,
}: Services): AsyncUseCase<Parameters, Callbacks> {
  return { execute }

  async function execute(email: Parameters, { onSuccess, onError }: Callbacks) {
    const emailCheck = await emailService.getEmailCheck(email)
    if (emailCheck.isErr() || !emailCheck.value.isValid) {
      onError()
      sendDatadogError({
        name: 'email-validator-error',
        options: { email },
      })
      return
    }

    sendDatadogEvent({
      name: 'email-validator',
      value: { email },
    })
    onSuccess()
  }
}

export { handleGetEmailCheck }
