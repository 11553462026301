import { NextStep } from '../../modules/lead/domain'
import { ConfigObject } from '../types'
const config: ConfigObject = {
  logo: {
    fileName: 'simon-emmanuel-brand.png',
    width: 314,
    height: 70,
    label: 'Simon Emmanuel',
    alt: 'SimonEmmanuel',
  },
  css: 'default.css',
  nextSteps: {
    show: false,
    withOffer: NextStep.OfferVisit,
    withoutOffer: NextStep.Call,
  },
  canSeeSimulationOffer: true,
}

export default config
