import { externalTarificatorService } from '@services/externalTarificatorService'
import {
  IAddressRepository,
  ISupplyPointsRepository,
} from '../domain/address.repository'
import {
  PostalCode,
  Municipality,
  ClientAddress,
} from '../domain/address.types'
import {
  MunicipalityDTO,
  SupplyPointParse,
  parseMuncipalititesToDomain,
  parseSupplyPointsToDomain,
  supplyPointDTO,
} from '../infrastructure/address.parser'

export const addressService: IAddressRepository = {
  getMunicipalities: (data: PostalCode) => {
    return externalTarificatorService.get<MunicipalityDTO[], Municipality[]>(
      {
        url: `/get-url?url=municipalities&postal-code=${data}`,
      },
      {
        toDomain: (municipalitiesDTO) =>
          municipalitiesDTO.map(parseMuncipalititesToDomain),
      }
    )
  },
}
export const supplyPointsService: ISupplyPointsRepository = {
  getSupplyPointsMatches: (data: ClientAddress) => {
    return externalTarificatorService.get<supplyPointDTO[], SupplyPointParse[]>(
      {
        url: `/get-url?url=supply-points&clientAddressMunicipality=${data.municipalityId}&clientAddressMunicipalityName=${data.municipalityCity}&clientAddressNumber=${data.number}&clientAddressStreet=${data.address}&clientAddressPostalCode=${data.postalCode}&clientAddressType=${data.streetType}&clientAddressDoorway=${data.doorway}&clientAddressStaircase=${data.stair}&clientAddressFloor=${data.floor}&clientAddressDoor=${data.door}`,
      },
      {
        toDomain: (supplyPointDTO) =>
          supplyPointDTO.map(parseSupplyPointsToDomain),
      }
    )
  },
}
