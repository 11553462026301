import { AnalyticsEventFactory } from '@holaluz/gin-tonic'
import modes from '../public-runtime.config'

export function useAnalytics() {
  const analyticsEventFactory = new AnalyticsEventFactory(
    ({ name, params }) => {
      if (modes.isProductionEnv || modes.isStagingEnv) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;(window as any).dataLayer.push({
          event: name,
          ...params,
        })
      }
    }
  )

  return {
    externalTarificatorAnalyticsFactory:
      analyticsEventFactory.getExternalTarificatorEvents(),
  }
}
