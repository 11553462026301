import { HttpStatusCode } from '../domain/HttpStatusCode'

interface IHttpError {
  status: number
  name: string
  message: string
  stack?: string
  data?: unknown
}

export class HttpError extends Error implements IHttpError {
  public status: number
  public data: unknown

  constructor(status: number, message?: string, data?: unknown) {
    super(message)

    Object.setPrototypeOf(this, HttpError.prototype)

    this.status = status
    this.name = HttpStatusCode[status]
    this.message = message || HttpStatusCode[status]
    this.data = data
  }

  public isClientError(): boolean {
    return this.status >= 400 && this.status <= 499
  }

  public isServerError(): boolean {
    return this.status >= 500 && this.status <= 599
  }

  public static fromStatus(status: number, message?: string, data?: unknown) {
    return new this(status, message, data)
  }

  public static fromMessage(message: string, status = 400) {
    return new this(status, message)
  }
}

export const isHttpError = (e: Error): e is HttpError => {
  return Number.isInteger((e as HttpError).status)
}
