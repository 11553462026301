import { ISolarOfferRepository } from '@modules/solarOffer/domain/solarOffer.repository'
import { Coordinates } from '@modules/address/domain/address.types'
import { SolarOffer } from '../domain/solarOffer.types'

type Parameters = {
  cups: string
  coordinates: Coordinates
  source?: string
}

type Services = {
  solarOfferService: ISolarOfferRepository
}

type Callbacks = {
  onSuccess: (data: SolarOffer) => void
  onError: () => void
}

function handleGetSolarOffer({
  solarOfferService,
}: Services): AsyncUseCase<Parameters, Callbacks> {
  return { execute }

  async function execute(
    { cups, coordinates, source }: Parameters,
    { onSuccess, onError }: Callbacks
  ) {
    const solarOffer = await solarOfferService.getSolarOffer(
      cups,
      coordinates,
      source
    )
    if (solarOffer.isErr()) {
      onError()
      return
    }

    onSuccess(solarOffer.value)
  }
}

export { handleGetSolarOffer }
