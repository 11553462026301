/* eslint-disable camelcase */
import { Municipality } from '../domain/address.types'

export type MunicipalityDTO = {
  id: string
  postal_code: string
  city: string
  province: {
    name: string
  }
  ine: string
}

export const parseMuncipalititesToDomain = ({
  id,
  postal_code,
  city,
  province,
  ine,
}: MunicipalityDTO): Municipality => ({
  id,
  postalCode: postal_code,
  city,
  province: province.name,
  ine,
})

export type supplyPointDTO = {
  cups: {
    value: string
  }
  address: string
}

export type SupplyPointParse = {
  cups: string
  street: string
}

export const parseSupplyPointsToDomain = ({
  cups,
  address,
}: supplyPointDTO): SupplyPointParse => ({
  cups: cups.value || '',
  street: address || '',
})
