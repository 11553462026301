import { NextStep } from '../../modules/lead/domain'
import { ConfigObject } from '../types'
const config: ConfigObject = {
  logo: {
    fileName: 'bysidecar.png',
    width: 164,
    height: 46,
    label: 'bysidecar',
    link: 'https://bysidecar.com/',
    alt: 'bysidecar, hacemos marketing que vende',
  },
  css: 'bysidecar.css',
  nextSteps: {
    show: false,
    withOffer: NextStep.OfferVisit,
    withoutOffer: NextStep.Call,
  },
  canSeeSimulationOffer: true,
}

export default config
