import { Lead } from '@modules/lead/domain/lead.types'
import { NextStep, Permissions } from '../domain'
import publicRuntimeConfig from '../../../public-runtime.config'

type ResellerAttributes = {
  category: string
  office: string
}
export type LeadDTO = {
  contact: {
    firstname: string
    phone: string
    email: string
    address: string
    zip: string
    province: string
    city: string
    comentarios: string
    permissions: Permissions
    agentecomercial: string
    lead_reference_number: string
    importeultimafactura: string
    momento_compra: string
    custom_referrer_url: string
    source: string
    coordenadas: string
    codigo_mgm: string
  }
  nextStep: NextStep | ''
  offer: {
    cups: string
    address: string
    offerId: string
    coordinates: object
    reseller?: string
    resellerAttributes?: ResellerAttributes
  }
}

const getResellerParams = () => {
  const reseller = publicRuntimeConfig.partner
  if (reseller === 'santander')
    return {
      reseller,
      resellerAttributes: {
        category: 'client',
        office: '0000 - 000000',
      },
    }
  return {}
}

export function parseFromDomain({
  contact: {
    firstname,
    phone,
    email,
    address: contactAddress,
    municipality,
    comments,
    permissions,
    commercialAgent,
    customerConsumption,
    buyMoment,
    customReferrerUrl,
    source,
    coordenadas: coordinates,
    coupon,
  },
  offer: { cups, address, offerId, coordinates: coordinatesObj },
  nextStep,
}: Lead): LeadDTO {
  return {
    contact: {
      firstname,
      phone,
      email,
      address:
        `${contactAddress.streetType} ${contactAddress.street} ${contactAddress.number} ${contactAddress.doorway} ${contactAddress.stair} ${contactAddress.floor} ${contactAddress.door}`
          .replace(/\s+/g, ' ')
          .trim(),
      zip: municipality.postalCode,
      province: municipality.province,
      city: municipality.city,
      comentarios: comments,
      permissions,
      agentecomercial: commercialAgent,
      lead_reference_number: commercialAgent,
      importeultimafactura: customerConsumption,
      momento_compra: buyMoment,
      custom_referrer_url: customReferrerUrl,
      source,
      coordenadas:
        coordinates.lat !== 0 ? `${coordinates.lat}, ${coordinates.lng}` : '',
      codigo_mgm: coupon,
    },
    nextStep,
    offer: {
      cups,
      address,
      offerId,
      coordinates: coordinatesObj,
      ...getResellerParams(),
    },
  }
}
