import { Plugin } from '@nuxt/types'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import publicRuntimeConfig from '../../public-runtime.config'

const initDatadog: Plugin = () => {
  if (publicRuntimeConfig.hasDatadog) {
    try {
      const datadogConfig = {
        applicationId: 'e68a62b8-f1c3-409f-a383-2b3d7614d37b',
        clientToken: 'pubee8efcce931b4fb4a28c99cf92b76e6e',
        env: publicRuntimeConfig.datadogEnv,
        site: 'datadoghq.eu',
        service: 'external-tarificator',
        sessionSampleRate: 100,
      }

      datadogRum.init({
        ...datadogConfig,
        defaultPrivacyLevel: 'allow',
        allowedTracingOrigins: [/https:\/\/.*\.holaluz\.com/],
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
      })

      datadogLogs.init({
        ...datadogConfig,
      })

      if (publicRuntimeConfig.isProductionEnv)
        datadogRum.startSessionReplayRecording()
    } catch (error) {
      if (!publicRuntimeConfig.isProductionEnv)
        // eslint-disable-next-line no-console
        console.error('Error loading datadog tracking', error)
    }
  } else if (!publicRuntimeConfig.isProductionEnv)
    // eslint-disable-next-line no-console
    console.info('Datadog tracking DISABLED')
}

export default initDatadog

export function sendDatadogEvent({
  name,
  value = {},
}: {
  name: string
  value: object
}) {
  if (publicRuntimeConfig.hasDatadog) {
    datadogRum.addAction(name, value)
  } else if (!publicRuntimeConfig.isProductionEnv) {
    // eslint-disable-next-line no-console
    console.info(name, value.toString())
  }
}

export function sendDatadogError({
  error,
  name,
  options,
}: {
  error?: string | Error
  name: string
  options: object
}) {
  const sendError = error || new Error(name)
  if (publicRuntimeConfig.hasDatadog) {
    datadogRum.addError(sendError, options)
  } else if (!publicRuntimeConfig.isProductionEnv) {
    // eslint-disable-next-line no-console
    console.error(sendError, options && options.toString())
  }
}
