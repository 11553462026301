import { ISolarUrlRepository } from '@modules/solarOffer/domain/solarOffer.repository'
import { SolarUrl } from '../domain/solarOffer.types'

type Parameters = {
  postalCode: string
}

type Services = {
  solarUrlService: ISolarUrlRepository
}

type Callbacks = {
  onSuccess: (data: SolarUrl) => void
  onError: () => void
}

function handleGetSolarUrl({
  solarUrlService,
}: Services): AsyncUseCase<Parameters, Callbacks> {
  return { execute }

  async function execute(
    { postalCode }: Parameters,
    { onSuccess, onError }: Callbacks
  ) {
    const solarUrl = await solarUrlService.getSolarUrl(postalCode)
    if (solarUrl.isErr()) {
      onError()
      return
    }

    onSuccess(solarUrl.value)
  }
}

export { handleGetSolarUrl }
