import { IAddressRepository } from '@modules/address/domain/address.repository'
import { PostalCode } from '@modules/address/domain/address.types'

type Parameters = { data: PostalCode }

type Services = {
  addressService: IAddressRepository
}

type Callbacks = {
  // fix any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess: (data: any) => void
  onError: () => void
}

function handleGetMunicipalities({
  addressService,
}: Services): AsyncUseCase<Parameters, Callbacks> {
  return { execute }

  async function execute(
    { data }: Parameters,
    { onSuccess, onError }: Callbacks
  ) {
    const municipalities = await addressService.getMunicipalities(data)

    if (municipalities.isErr()) {
      onError()
      return
    }

    onSuccess(municipalities)
  }
}

export { handleGetMunicipalities }
