import { ICreateLeadRepository } from '@modules/lead/domain/lead.repository'
import { Lead } from '@modules/lead/domain/lead.types'

type Parameters = { data: Lead }

type Services = {
  leadService: ICreateLeadRepository
}

type Callbacks = {
  onSuccess: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError: (error: any) => void
}

function handleCreateContactLead({
  leadService,
}: Services): AsyncUseCase<Parameters, Callbacks> {
  return { execute }

  async function execute(
    { data }: Parameters,
    { onSuccess, onError }: Callbacks
  ) {
    const createContactLead = await leadService.postCreateLead(data)

    if (createContactLead.isErr()) {
      onError(createContactLead)
      return
    }

    onSuccess()
  }
}

export { handleCreateContactLead }
