export type SolarOffer = {
  id: string
  panels: number
  installationValue: number
  scSaving: number
  solar: number
  solarBilling: number
  surplusSaving: number
  grossCustomerSavingsWithTaxes: number
  consumption: number
}

export const blankSolarOffer = (): SolarOffer => ({
  id: '',
  panels: 0,
  installationValue: 0,
  scSaving: 0,
  solar: 0,
  solarBilling: 0,
  surplusSaving: 0,
  grossCustomerSavingsWithTaxes: 0,
  consumption: 0,
})

export type SolarUrl = {
  urlBooking: string
  isVisit: boolean
}
