import { NextStep } from '../../modules/lead/domain'
import { ConfigObject } from '../types'
const config: ConfigObject = {
  logo: {
    fileName: 'afiliados.png',
    width: 158,
    height: 70,
    alt: 'Logo Afiliados',
  },
  css: 'default.css',
  nextSteps: {
    show: false,
    withOffer: NextStep.OfferVisit,
    withoutOffer: NextStep.Call,
  },
  canSeeSimulationOffer: true,
}

export default config
