import { externalTarificatorService } from '@services/externalTarificatorService'
import {
  ISolarOfferRepository,
  ISolarUrlRepository,
} from '../domain/solarOffer.repository'
import { SolarOffer, SolarUrl } from '../domain/solarOffer.types'
import { Coordinates } from '@modules/address/domain/address.types'
import {
  parseSolarOfferToDomain,
  SolarOfferDTO,
  parseSolarUrlToDomain,
  SolarUrlDTO,
} from './solarOffer.parser'
import publicRuntimeConfig from '../../../public-runtime.config'

const getResellerParams = () => {
  const reseller = publicRuntimeConfig.partner
  if (reseller === 'santander')
    return {
      reseller,
      resellerAttributes: JSON.stringify({
        category: 'client',
        office: '0000 - 000000',
      }),
    }
  return {}
}

export const solarOfferService: ISolarOfferRepository = {
  getSolarOffer: (cups: string, coordinates: Coordinates, source = 'WEB') => {
    return externalTarificatorService.get<SolarOfferDTO, SolarOffer>(
      {
        url: `/get-offer`,
        config: {
          params: {
            cups,
            latitude: coordinates.lat,
            longitude: coordinates.lng,
            source,
            ...getResellerParams(),
          },
        },
      },
      {
        toDomain: (solarOfferDTO) => parseSolarOfferToDomain(solarOfferDTO),
      }
    )
  },
}

export const solarUrlService: ISolarUrlRepository = {
  getSolarUrl: (postalCode: string) => {
    return externalTarificatorService.get<SolarUrlDTO, SolarUrl>(
      {
        url: `/get-url?url=urlBooking&postalCode=${postalCode}`,
      },
      {
        toDomain: (solarUrl) => parseSolarUrlToDomain(solarUrl),
      }
    )
  },
}
