import { NextStep } from '../../modules/lead/domain'
import { ConfigObject } from '../types'
const config: ConfigObject = {
  logo: {
    fileName: 'correos-brand.svg',
    width: 43,
    height: 40,
    label: 'Correos',
    link: 'https://correos.es/',
    alt: 'Correos',
  },
  css: 'correos.css',
  nextSteps: {
    show: false,
    withOffer: NextStep.Call,
    withoutOffer: NextStep.Call,
  },
  activeSections: [
    'agentId',
    'propertyInfo',
    'contactInfo',
    'customerConsumption',
  ],
  sendSMSGreetings: true,
}

export default config
