import { defineStore } from 'pinia'
import { NextStep } from '@modules/lead/domain'
import { handleCreateContactLead } from '@modules/lead/application/post-create-lead'
import { handleGetMunicipalities } from '@modules/address/application/get-municipalities'
import { handlegetSupplyPointsMatches } from '@modules/address/application/get-supplypoint-matches'
import { handleGetEmailCheck } from '@modules/email/application/get-email-check'
import { handleGetSolarOffer } from '@modules/solarOffer/application/get-solar-offer'
import { leadService } from '@modules/lead/infrastructure/createLead.service'
import {
  addressService,
  supplyPointsService,
} from '@modules/address/infrastructure/address.service'
import { emailService } from '@modules/email/infrastructure/email.service'
import { solarOfferService } from '@modules/solarOffer/infraestructure/solarOffer.service'
import {
  Coordinates,
  Municipality,
} from '@modules/address/domain/address.types'
import {
  SolarOffer,
  blankSolarOffer,
} from '@modules/solarOffer/domain/solarOffer.types'
import { formStoreState, ErrorDTO } from './types'
import { getNextStep, getSolarURL } from './helpers'
import { initialState } from './state'
import { sendDatadogError, sendDatadogEvent } from '@ui/plugins/datadog'
import { SupplyPointParse } from '@modules/address/infrastructure/address.parser'
import { ADDRESS_NOT_FOUND } from '@modules/address/domain/address'
import publicRuntimeConfig from '../../../public-runtime.config'
import { useAnalytics } from '@composables/useAnalytics'
import { isValidCups } from '@ui/utils/validators'

const { externalTarificatorAnalyticsFactory } = useAnalytics()

const createLead = handleCreateContactLead({ leadService })
const municipalities = handleGetMunicipalities({ addressService })
const supplyPoints = handlegetSupplyPointsMatches({ supplyPointsService })
const solarOffer = handleGetSolarOffer({ solarOfferService })
const emailCheck = handleGetEmailCheck({ emailService })

const LOCAL_STORE_KEY = 'agentInfo'

export const useFormStore = defineStore('form', {
  state: (): formStoreState => {
    return initialState
  },
  getters: {
    isClearStore(): boolean {
      return (
        !!this.hasPropertyConditions ||
        !!this.contact.firstname ||
        !!this.contact.email ||
        !!this.contact.phone ||
        !!this.contact.coupon ||
        !!this.contact.comments ||
        !!this.permissions.acceptPermissions ||
        !!this.municipality.postalCode ||
        !!this.supplyPoint.address.street ||
        !!this.supplyPoint.address.number ||
        !!this.supplyPoint.address.doorway ||
        !!this.supplyPoint.address.stair ||
        !!this.supplyPoint.address.floor ||
        !!this.supplyPoint.address.door ||
        !!this.supplyPoint.cups ||
        !!this.coordinates.lat ||
        !!this.coordinates.lng ||
        !!this.cups ||
        !!this.contact.customerConsumption ||
        !!this.contact.buyMoment ||
        !!this.googleAddress.formatedAddress ||
        !!this.supplyPointResults
      )
    },
    hasCoordinates(): boolean {
      return !!this.coordinates.lat && !!this.coordinates.lng
    },
    hasCoordinatesButMissingOfferForVisit(): boolean {
      const optionsWithOfferRequired = [
        NextStep.OfferVisit,
        NextStep.ScheduleVisit,
      ]
      const nextStepOptions =
        publicRuntimeConfig.partnerConfig.nextSteps.options || []

      const nextStepRequiresOffer =
        nextStepOptions.some((option) =>
          optionsWithOfferRequired.includes(option)
        ) ||
        optionsWithOfferRequired.includes(
          publicRuntimeConfig.partnerConfig.nextSteps.withOffer as NextStep
        )

      const defaultCondition = nextStepRequiresOffer && !this.solarOffer.id
      const ckCondition =
        !this.hasEmptySupplyPoints &&
        !this.getSafeCups &&
        this.supplyPoint.cups !== ADDRESS_NOT_FOUND

      if (publicRuntimeConfig.partnerConfig.isColdKnocker)
        return this.hasCoordinates && ckCondition
      return this.hasCoordinates && defaultCondition
    },
    hasSupplyPointsResults(): boolean {
      return !!this.supplyPointResults && this.supplyPointResults.length > 0
    },
    hasEmptySupplyPoints(): boolean {
      return this.supplyPointResults?.length === 0
    },
    getSafeCups(): string {
      const cups = this.cups && isValidCups(this.cups) ? this.cups : ''
      return this.supplyPoint.cups &&
        this.supplyPoint.cups !== ADDRESS_NOT_FOUND
        ? this.supplyPoint.cups
        : cups
    },
  },
  actions: {
    saveInLocalStorage(value: string) {
      const agentIdValue = {
        id: value,
      }
      const agentInfo = JSON.stringify(agentIdValue)

      localStorage.setItem(LOCAL_STORE_KEY, agentInfo)
    },
    getFromLocalStore() {
      const agentInfo = localStorage.getItem(LOCAL_STORE_KEY)
      const agentInfoParsed = JSON.parse(agentInfo || '{}')
      if (agentInfoParsed?.id) {
        this.commercialAgent = agentInfoParsed.id
      }
    },

    async getSupplyPointsMatches() {
      let municipalitiesResults: Municipality[] = []
      await municipalities.execute(
        { data: this.googleAddress.postalCode },
        {
          onSuccess: ({ value }: { value: Municipality[] }) => {
            municipalitiesResults = value
          },
          onError: () => {
            return (this.solarModuleErrors.hasErrorSearchingAddress = true)
          },
        }
      )
      if (!municipalitiesResults.length) {
        return (this.solarModuleErrors.hasErrorSearchingAddress = true)
      }
      this.municipality = municipalitiesResults[0]

      await supplyPoints.execute(
        {
          data: {
            municipalityId: this.municipality.id,
            municipalityCity: this.municipality.city,
            postalCode: this.municipality.postalCode,
            address:
              this.supplyPoint.address.street || this.googleAddress.roadName,
            streetType: this.supplyPoint.address.streetType,
            number:
              this.supplyPoint.address.number || this.googleAddress.roadNumber,
            doorway: this.supplyPoint.address.doorway,
            stair: this.supplyPoint.address.stair,
            floor: this.supplyPoint.address.floor,
            door: this.supplyPoint.address.door,
          },
        },
        {
          onSuccess: ({ value }: { value: SupplyPointParse[] }) => {
            const parsedSupplyPoints = value.map(
              (supplyPoint: SupplyPointParse) => ({
                text: supplyPoint.street,
                value: supplyPoint.cups,
              })
            )

            const supplyPointResult = [...parsedSupplyPoints]
            if (supplyPointResult.length === 0) {
              this.solarModuleErrors.hasErrorSearchingAddress = true
            } else if (supplyPointResult.length === 1) {
              this.supplyPoint.cups = supplyPointResult[0].value
            }
            this.supplyPointResults = supplyPointResult
          },
          onError: () => {
            return (this.solarModuleErrors.hasErrorSearchingAddress = true)
          },
        }
      )
    },

    async getSolarOffer() {
      this.solarModuleErrors.hasErrorInSimulation = false
      await solarOffer.execute(
        {
          cups: this.getSafeCups,
          coordinates: this.coordinates,
          source: publicRuntimeConfig.partnerConfig.isColdKnocker
            ? 'COLDKNOCKING'
            : 'EXTERNAL_TARIFICATOR',
        },
        {
          onSuccess: (data: SolarOffer) => {
            this.solarOffer = data
          },
          onError: () => {
            this.solarModuleErrors.hasErrorInSimulation = true
            externalTarificatorAnalyticsFactory.tariffSolarKo('ko_backend')
          },
        }
      )
    },
    async emailCheck(email: string) {
      await emailCheck.execute(email, {
        onSuccess: () => {
          this.contact.isEmailValid = true
        },
        onError: () => {
          this.contact.isEmailValid = false
        },
      })
    },

    async postCreateLead({
      onLeadCreated,
      onServerError,
    }: {
      onLeadCreated: () => void
      onServerError: (data: ErrorDTO) => void
    }) {
      const cups = this.getSafeCups

      const nextStepsSelection: NextStep | '' = getNextStep({
        withoutOffer: !this.solarOffer.id,
        selectedStep: this.nextStep,
      })

      const addUserDataToUrl = (url: string): string => {
        const symbol = url.includes('?') ? '&' : '?'
        let phone = this.contact.phone
        if (!phone.includes('+34')) {
          phone = `+34${phone}`
        }
        const urlSearchParams = new URLSearchParams()
        urlSearchParams.append('Name', this.contact.firstname)
        urlSearchParams.append('Email', this.contact.email)
        urlSearchParams.append('Contact Number', phone)
        urlSearchParams.append('Origin', 'WebET')

        return `${url}${symbol}${urlSearchParams.toString()}`
      }

      function getURL() {
        // Amazon Waf protection does not allow us to send 'localhost' in the request body
        return publicRuntimeConfig.isDevelopmentEnv ||
          publicRuntimeConfig.isQaEnv
          ? 'development'
          : window.location.href
      }

      const dataObject = {
        partner: this.$state.partner,
        contact: {
          ...this.$state.contact,
          address: {
            ...this.$state.supplyPoint.address,
            streetType: this.supplyPoint.address.street
              ? this.supplyPoint.address.streetType
              : '',
            street:
              this.supplyPoint.address.street ||
              this.googleAddress.formatedAddress,
          },
          municipality: this.$state.municipality,
          permissions: {
            consentToProcess: !!this.permissions.acceptPermissions,
          },
          commercialAgent: this.commercialAgent,
          customReferrerUrl: getURL(),
          source: publicRuntimeConfig.partnerConfig.isColdKnocker
            ? 'COLDKNOCKING'
            : 'EXTERNAL_TARIFICATOR',
          coordenadas: this.$state.coordinates,
        },
        nextStep: nextStepsSelection,
        offer: {
          cups,
          address: this.municipality.city,
          offerId: this.solarOffer.id,
          coordinates: this.$state.coordinates,
        },
      }

      await createLead.execute(
        {
          data: dataObject,
        },
        {
          onSuccess: () => {
            sendDatadogEvent({
              name: 'create-contact',
              value: dataObject,
            })
            if (
              (nextStepsSelection === NextStep.OfferVisit ||
                nextStepsSelection === NextStep.ScheduleVisit) &&
              (this.solarOffer.id ||
                publicRuntimeConfig.partnerConfig.isColdKnocker)
            ) {
              getSolarURL({
                onSuccess: (data) => {
                  this.visitUrl = addUserDataToUrl(data.urlBooking)
                  this.isVisit = data.isVisit
                  sendDatadogEvent({
                    name: 'url-booking',
                    value: { ...dataObject, isVisit: data.isVisit },
                  })
                  onLeadCreated()
                },
                onServerError: () => {
                  this.scheduleError = true
                  sendDatadogError({
                    name: 'url-booking-error',
                    options: dataObject,
                  })
                  onLeadCreated()
                },
                postalCode: this.$state.municipality.postalCode,
              })
            } else {
              if (nextStepsSelection === NextStep.OfferVisit) {
                this.isVisit = false
                this.visitUrl = addUserDataToUrl(
                  publicRuntimeConfig.videoCallURL
                )
              }
              onLeadCreated()
            }
          },
          onError: (data: ErrorDTO) => {
            sendDatadogError({
              name: 'create-contact-error',
              options: {
                ...dataObject,
                error: data.error,
              },
            })
            onServerError(data)
          },
        }
      )
    },

    clearLead() {
      this.hasPropertyConditions = false
      this.contact.firstname = ''
      this.contact.email = ''
      this.contact.isEmailValid = true
      this.contact.phone = ''
      this.contact.customerConsumption = ''
      this.contact.buyMoment = ''
      this.contact.comments = ''
      this.contact.coupon = ''
      this.permissions.acceptPermissions = ''
      this.nextStep = ''
      this.submitSuccess = false
      this.hasServerError = false
      this.hasFormError = false
      this.hasInvalidPhone = false
      this.hasInvalidEmail = false
      this.clearSolarOffer()
      this.municipality = {
        postalCode: '',
        id: '',
        province: '',
        city: '',
        ine: '',
      }
      this.cups = ''
      this.supplyPointResults = undefined
      this.supplyPoint = {
        cups: '',
        address: {
          street: '',
          streetType: 'CL',
          number: '',
          doorway: '',
          stair: '',
          floor: '',
          door: '',
        },
      }
      this.coordinates = {
        lat: 0,
        lng: 0,
      }
      this.googleAddress = {
        roadNumber: '',
        roadName: '',
        locality: '',
        province: '',
        postalCode: '',
        autonomousCommunity: '',
        formatedAddress: '',
      }
      this.solarModuleErrors = {
        hasErrorSearchingAddress: false,
        hasErrorInSimulation: false,
      }
    },

    clearStore() {
      this.clearLead()
      this.visitUrl = ''
      this.launchResetValidation()
    },

    clearSolarOffer() {
      this.solarOffer = blankSolarOffer()
    },

    setResetValidation(reset: () => void) {
      this.resetValidation = reset
    },

    launchResetValidation() {
      this.resetValidation()
    },
    setPartner(partner: string) {
      this.partner = partner
    },
    setGoogleAddress(
      place: google.maps.places.PlaceResult,
      location?: Coordinates
    ) {
      const address = Object.values(place?.address_components || {})
      this.googleAddress.roadNumber =
        address?.find((element) => {
          return element.types.includes('street_number')
        })?.long_name || ''
      this.googleAddress.roadName =
        address?.find((element) => {
          return element.types.includes('route')
        })?.long_name || ''
      this.googleAddress.locality =
        address?.find((element) => {
          return element.types.includes('locality')
        })?.long_name || ''
      this.googleAddress.postalCode =
        address?.find((element) => {
          return element.types.includes('postal_code')
        })?.long_name || ''
      this.googleAddress.province =
        address?.find((element) => {
          return element.types.includes('administrative_area_level_2')
        })?.long_name || ''
      this.googleAddress.autonomousCommunity =
        address?.find((element) => {
          return element.types.includes('administrative_area_level_1')
        })?.long_name || ''
      this.googleAddress.formatedAddress = place.formatted_address || ''
      this.coordinates.lat =
        location?.lat || place?.geometry?.location?.lat() || 0
      this.coordinates.lng =
        location?.lng || place?.geometry?.location?.lng() || 0
    },
  },
  persist: true,
})
