import { handleGetSolarUrl } from '@modules/solarOffer/application/get-solar-url'
import { solarUrlService } from '@modules/solarOffer/infraestructure/solarOffer.service'
import { SolarUrl } from '@modules/solarOffer/domain/solarOffer.types'
import { NextStep } from '@modules/lead/domain'
import publicRuntimeConfig from '../../../public-runtime.config'

const solarUrl = handleGetSolarUrl({ solarUrlService })

export const getSolarURL = async ({
  onSuccess,
  onServerError,
  postalCode,
}: {
  onSuccess: (data: SolarUrl) => void
  onServerError: () => void
  postalCode: string
}) => {
  await solarUrl.execute(
    {
      postalCode,
    },
    {
      onSuccess: (data) => {
        onSuccess(data)
      },
      onError: () => {
        onServerError()
      },
    }
  )
}

export const getNextStep = ({
  withoutOffer,
  selectedStep,
}: {
  withoutOffer: boolean
  selectedStep: NextStep | ''
}): NextStep | '' => {
  const stepWithoutOffer =
    selectedStep ||
    publicRuntimeConfig.partnerConfig.nextSteps.withoutOffer ||
    NextStep.Call
  const stepWithOffer =
    selectedStep || publicRuntimeConfig.partnerConfig.nextSteps.withOffer || ''
  return withoutOffer ? stepWithoutOffer : stepWithOffer
}
