const CUPS_REGEX = /^[A-Z]{2}\d{16}[A-Z]{2}\d{0,1}[A-Z]{0,1}$/
const GAS_CUPS_PREFIX = 'ES02'
const CHECKSUM_TABLE = 'TRWAGMYFPDXBNJZSQVHLCKE'.split('')

const fmod = (a: number, b: number): number =>
  Number((a - Math.floor(a / b) * b).toPrecision(8))

const checkValidChecksum = (cups: string) => {
  const digits = cups.substring(2, 18)
  const expectedChecksum = cups.substring(18, 20)

  const coefficient = fmod(+digits, 529)

  const firstNumber = Math.floor(coefficient / 23)
  const secondNumber = coefficient % 23

  const firstDigit = CHECKSUM_TABLE[firstNumber]
  const secondDigit = CHECKSUM_TABLE[secondNumber]

  return expectedChecksum === firstDigit + secondDigit
}

const isGasCups = (cups: string) => cups.startsWith(GAS_CUPS_PREFIX)

const isValidCups = (cups: string) =>
  !isGasCups(cups) && cups.match(CUPS_REGEX) ? checkValidChecksum(cups) : false

export { isValidCups }
