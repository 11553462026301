import { NextStep } from '../../modules/lead/domain'
import { ConfigObject } from '../types'
const config: ConfigObject = {
  logo: {
    fileName: 'convertia-brand.png',
    width: 182,
    height: 70,
    label: 'Convertia',
    link: 'https://convertia.com/',
    alt: 'Convertia',
  },
  css: 'default.css',
  nextSteps: {
    show: false,
    withOffer: NextStep.OfferVisit,
    withoutOffer: NextStep.Call,
  },
  canSeeSimulationOffer: true,
}

export default config
