import { ISupplyPointsRepository } from '@modules/address/domain/address.repository'
import { ClientAddress } from '@modules/address/domain/address.types'
import { SupplyPointParse } from '../infrastructure/address.parser'

type Parameters = { data: ClientAddress }

type Services = {
  supplyPointsService: ISupplyPointsRepository
}

type Callbacks = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess: (data: { value: SupplyPointParse[] }) => void
  onError: () => void
}

function handlegetSupplyPointsMatches({
  supplyPointsService,
}: Services): AsyncUseCase<Parameters, Callbacks> {
  return { execute }

  async function execute(
    { data }: Parameters,
    { onSuccess, onError }: Callbacks
  ) {
    const addresses = await supplyPointsService.getSupplyPointsMatches(data)

    if (addresses.isErr()) {
      return onError()
    }

    onSuccess(addresses)
  }
}

export { handlegetSupplyPointsMatches }
