import { NextStep } from '../../modules/lead/domain'
import { ConfigObject } from '../types'
const config: ConfigObject = {
  logo: {
    fileName: 'santander-brand.png',
    width: 105,
    height: 35,
    label: 'Santander',
    alt: 'Santander',
  },
  css: 'santander.css',
  nextSteps: {
    show: true,
    options: [NextStep.OfferVisit, NextStep.ScheduleVisit],
  },
  activeSections: ['contactInfo', 'solarModuleMap', 'contactPermissions'],
  isColdKnocker: false,
  mapsMarker: 'icon-marker-santander.png',
  hasPremiumLeadEnabled: true,
}

export default config
