/* eslint-disable */
import modes from '../../public-runtime.config'

export default () => {
  if (modes.isProductionEnv || modes.isStagingEnv) {
    ;(function (w, d, s, l: any, i) {
      w[l] = w[l] || []
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
      var f = d.getElementsByTagName(s)[0] as any,
        j = d.createElement(s) as any,
        // eslint-disable-next-line prefer-template
        dl = l != 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
    })(window as any, document, 'script', 'dataLayer', 'GTM-TNVCTWT')
    const urlParams = new URLSearchParams(window.location.search)
    const partner = urlParams.get('partner')
    ;(window as any).dataLayer.push({
      application: 'tarification_partnership',
      business_unit: 'solar',
      partnership: modes.partner === 'afiliados' ? partner : modes.partner,
    })
  }
}
