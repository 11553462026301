import { NextStep } from '../../modules/lead/domain'
import { ConfigObject } from '../types'
const config: ConfigObject = {
  logo: {
    fileName: 'coldknockers-logo.png',
    width: 290,
    height: 70,
    label: 'Referidos',
    link: 'https://www.holaluz.com/',
    alt: 'Simulador Solar',
  },
  css: 'default.css',
  nextSteps: {
    show: false,
    withOffer: NextStep.OfferVisit,
    withoutOffer: NextStep.OfferVisit,
  },
  isColdKnocker: true,
  hasCouponEnabled: true,
  activeSections: [
    'agentId',
    'contactInfo',
    'solarModuleMap',
    'contactPermissions',
  ],
}

export default config
