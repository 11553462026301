import { NextStep } from '../../modules/lead/domain'
import { ConfigObject } from '../types'
const config: ConfigObject = {
  logo: {
    fileName: 'lucas-fox-brand.png',
    width: 297,
    height: 70,
    label: 'Lucas Fox',
    link: 'https://www.lucasfox.es',
    alt: 'Lucas Fox',
  },
  css: 'default.css',
  nextSteps: {
    show: false,
    withOffer: NextStep.CallOffer,
    withoutOffer: NextStep.Call,
  },
  canSeeSimulationOffer: true,
}

export default config
