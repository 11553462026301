import { externalTarificatorService } from '@services/externalTarificatorService'
import { ICreateLeadRepository } from '../domain/lead.repository'
import { Lead } from '@modules/lead/domain/lead.types'
import { LeadDTO, parseFromDomain } from './createLead.parser'
import publicRuntimeConfig from '../../../public-runtime.config'

const leadService: ICreateLeadRepository = {
  postCreateLead: (data) => {
    const { partner } = data
    const urlPrefix = publicRuntimeConfig.partnerConfig.isColdKnocker
      ? 'create-contact-ck'
      : `create-contact`
    const parsedData = parseFromDomain(data)
    return externalTarificatorService.post<Lead[], LeadDTO[]>({
      url: `/${urlPrefix}?partner=${partner}`,
      data: parsedData,
    })
  },
}

export { leadService }
