
import TheHeader from './_components/TheHeader'

export default {
  name: 'LayoutDefault',

  components: {
    TheHeader,
  },
}
