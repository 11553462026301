import { SolarOffer, SolarUrl } from '../domain/solarOffer.types'

export type SolarFeeDTO = {
  scSaving: string
  solar: string
  solarBilling: string
  surplusBilling: string
  surplusSaving: string
}

export type SolarOfferDTO = {
  type: string
  id: string
  panels: number
  installationValue: number
  grossCustomerSavingsWithTaxes: number
  fee: SolarFeeDTO
  consumption: number
}

export const parseSolarOfferToDomain = (
  solarOfferDTO: SolarOfferDTO
): SolarOffer => {
  return {
    scSaving: Number(solarOfferDTO.fee.scSaving),
    solar: Number(solarOfferDTO.fee.solar),
    solarBilling: Number(solarOfferDTO.fee.solarBilling),
    surplusSaving: Number(solarOfferDTO.fee.surplusSaving),
    id: solarOfferDTO.id,
    panels: solarOfferDTO.panels,
    installationValue: solarOfferDTO.installationValue,
    grossCustomerSavingsWithTaxes: solarOfferDTO.grossCustomerSavingsWithTaxes,
    consumption: solarOfferDTO.consumption,
  }
}

export type SolarUrlDTO = {
  url_booking: string
  is_visit: boolean
}

export const parseSolarUrlToDomain = (solarUrlDTO: SolarUrlDTO): SolarUrl => ({
  urlBooking: solarUrlDTO.url_booking,
  isVisit: solarUrlDTO.is_visit,
})
