import { render, staticRenderFns } from "./NewUserModal.vue?vue&type=template&id=129314c4&scoped=true&"
import script from "./NewUserModal.vue?vue&type=script&setup=true&lang=ts&"
export * from "./NewUserModal.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./NewUserModal.css?vue&type=style&index=0&id=129314c4&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "129314c4",
  null
  
)

/* custom blocks */
import block0 from "./NewUserModal.yml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc3539722193%2Fsrc%2Fexternal-tarificator%2Fsrc%2Fui%2Flayouts%2F_components%2FTheHeader%2F_components%2FNewUserModal.vue&lang=yml"
if (typeof block0 === 'function') block0(component)

export default component.exports