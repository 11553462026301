import { externalTarificatorService } from '@services/externalTarificatorService'
import { IEmailRepository } from '../domain/email.repository'
import { Email, EmailDTO } from '../domain/email.types'

export const emailService: IEmailRepository = {
  getEmailCheck: (email: Email) => {
    return externalTarificatorService.post<EmailDTO, EmailDTO>({
      url: `/validate/mail`,
      data: { email },
    })
  },
}
