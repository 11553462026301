import { NextStep } from '../../modules/lead/domain'
import { ConfigObject } from '../types'
const config: ConfigObject = {
  logo: {
    fileName: 'partners-brand.png',
    width: 154,
    height: 70,
    label: 'Logo partner',
    link: 'https://holaluz.com/',
    alt: 'Partner',
  },
  css: 'default.css',
  nextSteps: {
    show: true,
    options: [NextStep.OfferVisit, NextStep.ScheduleVisit],
  },
  activeSections: ['contactInfo', 'solarModuleMap', 'contactPermissions'],
  isColdKnocker: false,
  hasPremiumLeadEnabled: true,
}

export default config
