import { Plugin } from '@nuxt/types'
import { isValidCups } from '@ui/utils/validators'
import { extend, configure } from 'vee-validate'
import { required, numeric, email } from 'vee-validate/dist/rules'
import VueI18n from 'vue-i18n'

const veeValidate: Plugin = ({ app }) => {
  configureVeeValidate(app.i18n as VueI18n)
}

export default veeValidate

export function configureVeeValidate(i18n: VueI18n) {
  configure({
    defaultMessage: (_, values) =>
      i18n.t(`fieldErrors.${values._rule_}`, values) as string,
  })

  extend('required', required)
  extend('numeric', numeric)
  extend('email', email)
  extend('phone', {
    validate: () => {
      return true
    },
  })
  extend('trueRequired', {
    ...required,
    validate: (value) => value,
  })
  extend('isValidPostalCode', {
    validate: (municipality) => {
      const { id } = municipality
      return id !== undefined
    },
  })
  extend('isRequiredPostalCode', {
    validate: (municipality) => {
      const { id } = municipality
      return id !== ''
    },
  })
  extend('isValidAddress', {
    validate: (selectedStreet) => {
      const { cups } = selectedStreet
      return cups !== undefined
    },
  })
  extend('isValidCups', {
    validate: (cups: string) => {
      return cups ? isValidCups(cups) : false
    },
  })
}
