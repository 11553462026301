import { NextStep } from '../../modules/lead/domain'
import { ConfigObject } from '../types'
const config: ConfigObject = {
  logo: {
    fileName: 'inmark-brand.png',
    width: 252,
    height: 70,
    label: 'Grupo Inmark',
    link: 'https://www.grupoinmark.com',
    alt: 'Grupo Inmark',
  },
  css: 'default.css',
  nextSteps: {
    show: false,
    withOffer: NextStep.OfferVisit,
    withoutOffer: NextStep.OfferVisit,
  },
  isColdKnocker: true,
}

export default config
